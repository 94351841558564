/* -------------------------------------------------------------------
* STYLE.CSS
* Feuille de style principale
*
* 1. FONTS
* 2. VARIABLES
* 3. CLASSES DE BASE
* 4. NAVIGATION
* 5. HEADER
* 6. FOOTER
* 7. BLOG
* 8. CLASSES LOCALES
*
* ----------------------------------------------------------------- */


/* -------------------------------------------------------------------
* 1/ FONTS
* ----------------------------------------------------------------- */
@font-face {
    font-family: sofia-extra-condensed-regular;
    font-weight: 400;
    font-display: swap;
    src: url(https://cdn.thetalentist.com/fonts/sofia-sans/fonts/webfonts/Extra-Condensed/SofiaSansExtraCondensed-Regular.woff2);
}

@font-face {
    font-family: sofia-extra-condensed-bold;
    font-weight: 700;
    font-display: swap;
    src: url(https://cdn.thetalentist.com/fonts/sofia-sans/fonts/webfonts/Extra-Condensed/SofiaSansExtraCondensed-Bold.woff2);
}

/* -------------------------------------------------------------------
* 2. VARIABLES
* ----------------------------------------------------------------- */
:root {
    --serif:          Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

    --title-font:     sofia-extra-condensed-bold;
    --title-font-alt: sofia-extra-condensed-regular;
    --text-font:      var(--serif);

    --blush:      #fffafa;
    --thistle:    #d2c5cd;
    --china-rose: #a9688a;
    --aubergine:  #6b405a;
    --talentist:  #360242;

    --bg:        var(--blush);
    --bg-alt:    var(--china-rose);
    --accent-bg: var(--thistle);

    --text:        var(--aubergine);
    --text-light:  var(--china-rose);
    --accent-text: var(--bg);

    --accent:       var(--china-rose);
    --accent-hover: var(--thistle);

    --border: var(--thistle);
    --disabled: #efefef;
}

@media (prefers-color-scheme: dark) {
:root {
    color-scheme: dark;
    --blush:      #fffafa;
    --thistle:    #d2c5cd;
    --china-rose: #a9688a;
    --aubergine:  #6b405a;
    --talentist:  #360242;

    --bg:        var(--blush);
    --bg-alt:    var(--china-rose);
    --accent-bg: var(--thistle);

    --text:        var(--aubergine);
    --text-light:  var(--china-rose);
    --accent-text: var(--bg);

    --accent:       var(--china-rose);
    --accent-hover: var(--thistle);

    --border: var(--thistle);
    --disabled: #efefef;
    }
img,
video {
    opacity: 1.0;
}
}

/* -------------------------------------------------------------------
* 3. CLASSES DE BASE
* ----------------------------------------------------------------- */
main p,
main li,
main blockquote,
main dl {
    hyphens: auto;
    font-family: var(--text-font);
    font-size: 1.25rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--title-font);
}

h2 {
    color: var(--text-light);
    text-transform: uppercase;
}

button,
.button,
a.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    background-color: var(--bg-alt);
    color: var(--accent-text);
    padding: 0.5rem 0.9rem;
    text-decoration: none;
    line-height: normal;
}

button:enabled:hover,
.button:not([aria-disabled="true"]):hover,
input[type="submit"]:enabled:hover,
input[type="reset"]:enabled:hover,
input[type="button"]:enabled:hover {
    background-color: var(--accent-hover);
    color: var(--text-light);
    cursor: pointer;
}

hr {
    border: 2px solid var(--thistle);
    margin: 3rem 0 3rem 0;
}

li {
    list-style-type: "❃ ";
}

article, fieldset, dialog {
  border: none;
  padding: 0;
  margin-bottom: 1rem;
}

dl {
    font-family: var(--text-font);
}

dt a {
    text-decoration: none;
}

code {
    font-size: .90rem;
    color: inherit;
}

.notice {
    background: var(--bg);
    color: var(--text-light);
    border: none;
}

.notice > h2 {
    font-style: italic;
    text-align: center;
    text-transform: none;
}

::selection {
    color: var(--bg);
    background-color: var(--text-light);
}

/* -------------------------------------------------------------------
* 4. NAVIGATION
* ----------------------------------------------------------------- */
.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation > a {
    font-variant: small-caps;
    text-decoration: none;
    color: var(--text);
}

.navigation > nav {
    font-size: 1rem;
    line-height: 2;
    padding: 1rem 0 0 0;
}

.navigation > nav ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigation > nav ul li {
    display: inline-block;
}

.navigation > nav a,
.navigation > nav a:visited {
    display: inline-block;
    margin: 0 0.5rem 1rem 0.5rem;
    padding: 0.1rem 1rem;
    border-radius: var(--standard-border-radius);
    background-color: var(--text);
    color: var(--bg);
    text-decoration: none;
}

.navigation > nav a:hover,
.navigation > nav a.current,
.navigation > nav a[aria-current="page"],
.navigation > nav a[aria-current="true"] {
    background-color: var(--bg-alt);
}

.link-home { display: none;}
@media (min-width: 720px) {
    .link-home { display: inline;}
}
/* -------------------------------------------------------------------
* 5. HEADER
* ----------------------------------------------------------------- */
header.homepage-header {
    height: 100vh;
    min-height: 600px;
    background-image: url("/images/bandeau.jpg");
    background-color: var(--aubergine);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--bg);
}

header.homepage-header h1,
header.homepage-header h2,
header.homepage-header p {
    text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

header.homepage-header h1 {
    font-size: 4em;
    padding-top: 2rem;
}

header.homepage-header h2 {
    margin: 1em 0 1em 0;
    font-size: 2em;
    color: var(--bg);
}

header.homepage-header p {
    font-size: 2em;
    line-height: 1.10em;
    font-family: var(--title-font-alt);
}

.title-star {
    display: none;
}

@media (min-width: 720px) {
    header.homepage-header h1 {
        font-size: 6em;
    }
    header.homepage-header h2 {
        margin: 1em 0 1em 0;
        font-size: 4em;
        color: var(--bg);
    }
    header.homepage-header p {
        font-size: 3em;
        font-family: var(--title-font-alt);
    }
    .title-star {
        display: inline;
        font-size: 0.75em;
        vertical-align: 10%;
        padding: 0 0.50em 0 0.50em;
    }
}
/* -------------------------------------------------------------------
* 6. FOOTER
* ----------------------------------------------------------------- */
/*
footer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
*/
footer {
    display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "pages contact"
    "license license"
}

footer h5 {
    margin: 0 0 0.5em 0;
}

footer ul {
    flex: 1;
    justify-content: flex-end;
    // text-align: left;
    padding: 0;
    min-width: 50%;
}

footer ul li {
    list-style-type: none;
}

footer ul li a {
    text-decoration: none;
    color: var(--text);
    font-family: var(--title-font-alt);
    font-size: 1.25em;
}

.footer-left {
    text-align: left;
}

.footer-right {
    text-align: right;
}

.footer-pages {
    grid-area: pages;
    text-align: left;
}

.footer-contact {
    grid-area: contact;
    text-align: right;
}

.footer-license {
    grid-area: license;
    border-top: 3px solid var(--accent-bg);
}
/* -------------------------------------------------------------------
 * 7. BLOG
* ----------------------------------------------------------------- */
header.post-header {
    margin: 5rem 0 5rem 0;
    background: none;
    text-align: center;
    color: var(--text);
    font-family: var(--title-font);
}

header.post-header h1,
header.post-header h2,
header.post-header p {
    text-shadow: none;
}

header.post-header h1.post-title {
    padding: 0;
    margin: 0;
    text-transform: capitalize;
}

header.post-header p {
    font-family: var(--title-font);
}

div.post-content p:first-child {
    font-size: 1.50rem;
    line-height: 1.25em;
}

.post-meta__title {
    /* TODO */
}

.post-meta__description {
    /* TODO */
}

.post-meta__date {
    font-family: var(--title-font);
    font-size: 1rem;
}

/* -------------------------------------------------------------------
* 8. CLASSES LOCALES
* ----------------------------------------------------------------- */
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 10px;
}

.container-posts {
    justify-content: space-between;
}

.card-post,
.container-post__vedette {
    justify-content: space-between;
    background: white;
    text-decoration: none;
    box-shadow: 0px 0px 5px #ccc;
    font-family: var(--title-font);
}

.card {
    display: block;
    background-color: var(--accent-bg);
    flex-grow: 0;
    flex-shrink: 0;
}

.card-1 {
    flex-basis: 100vw;
}
@media (min-width: 720px) {
.card-1 {
    flex-basis: 45rem;
}
}

.card-2 {
    flex-basis: 18em;
}

.card-4 {
    flex-basis: 12em;
}
@media (min-width: 720px) {
.card-4 {
    flex-basis: 9em;
}
}

.card-post {
    width: 340px;
    background: none;
    font-family: var(--title-font);
    text-decoration: none;
    color: var(--text);
}
@media (min-width: 720px) {
.card-post {
    width: 340px;
}
}

.card-post > img,
.container-post__vedette > .card {
    background: none;
    border-radius : 0;
}

.card h4 {
    line-height: 1.5rem;
    padding: 0 0.5em 0 0.5em;
    color: var(--text);
}

.card p {
    margin: 0;
    padding: 0 0.5em 0 0.5em;
    color: var(--text);
}

.bourrin {
    text-transform: uppercase;
    text-align: center;
}

.first-paragraph {
    font-size: 1.50rem;
    line-height: 1.25em;
}

.transparent {
    background-color: var(--bg);
}

.spacer {
  margin: 5rem 0;
}

.icon-medium {
    width: 100%;
    height: 48px;
    text-align: center;
    fill: var(--text);
    margin-top: 16px;
}

.icon-small {
    width:  22px;
    height: 22px;
    fill: var(--text);
}

.links-icon {
    width: 32px;
    height: 32px;
    margin: 0 0.25em 0 0.25em;
    fill: var(--text);
}

.links {
    display: flex;
}

.four-oh-four {
    display: flex;
    justify-content: center;
}

.four-oh-four p {
    font-size: 12rem;
}

.centered,
.call-to-action {
    text-align: center;
}

.no-radius {
    border-radius: 0;
}

.sans-serif {
    font-family: var(--title-font-alt);
}

.badge {
    background-color: var(--bg);
    text-align: center;
}

.badge a {
    text-decoration: none;
}

.badge a p {
    font-family: var(--title-font-alt);
}
